var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showRequestAccess)?_c('ModalRequestAccount',{attrs:{"showModal":_vm.showRequestAccess,"onClickCancel":_vm.hideModal},on:{"reloadList":_vm.getLocationState}}):_vm._e(),(!_vm.locationState.LocationAccountRights)?_c('div',[_c('a',{on:{"click":_vm.setShowModalRequestAccess}},[_vm._v("Request access")])]):_vm._e(),(
      _vm.locationState.LocationAccountRights &&
      _vm.locationState.LocationAccountRights.Status === 'Request'
    )?_c('div',[_vm._v(" Request pending ")]):_vm._e(),(
      _vm.locationState.LocationAccountRights &&
      _vm.locationState.LocationAccountRights.Status === 'Denied'
    )?_c('div',[_vm._v(" Request denied "),_c('a',{on:{"click":_vm.setShowModalRequestAccess}},[_vm._v("retry")])]):_vm._e(),(
      _vm.locationState.LocationAccountRights &&
      _vm.locationState.LocationAccountRights.Status === 'Accepted'
    )?_c('div',[_c('a',{staticClass:"has-icon",on:{"click":_vm.goToManage}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'share']}})],1),_c('span',[_vm._v("Go to manage")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }