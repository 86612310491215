<template>
  <div>
    <ModalRequestAccount
      v-if="showRequestAccess"
      :showModal="showRequestAccess"
      :onClickCancel="hideModal"
      @reloadList="getLocationState"
    />

    <div v-if="!locationState.LocationAccountRights">
      <a @click="setShowModalRequestAccess">Request access</a>
    </div>
    <div
      v-if="
        locationState.LocationAccountRights &&
        locationState.LocationAccountRights.Status === 'Request'
      "
    >
      Request pending
    </div>
    <div
      v-if="
        locationState.LocationAccountRights &&
        locationState.LocationAccountRights.Status === 'Denied'
      "
    >
      Request denied <a @click="setShowModalRequestAccess">retry</a>
    </div>
    <div
      v-if="
        locationState.LocationAccountRights &&
        locationState.LocationAccountRights.Status === 'Accepted'
      "
    >
      <a @click="goToManage" class="has-icon">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'share']" />
        </span>
        <span>Go to manage</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import locationProvider from '@/providers/location'

const ModalRequestAccount = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/ModalRequestAccount'
  )

export default {
  components: {
    ModalRequestAccount,
  },

  props: {},

  data() {
    return {
      showRequestAccess: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {},

  methods: {
    ...mapMutations('locationStore', ['setLocationState']),

    getLocationState() {
      locationProvider.methods
        .getLocationState(this.locationState.Id)
        .then((response) => {
          if (response.status === 200) {
            this.setLocationState(response.data)
          }
        })
        .catch((err) => {})
    },

    requestLocationRights() {},

    goToManage() {
      window.open(process.env.VUE_APP_MANAGE_URL + '/' + this.locationState.Id)
    },

    setShowModalRequestAccess() {
      this.showRequestAccess = true
    },

    hideModal() {
      this.showRequestAccess = false
    },
  },
}
</script>

<style></style>
